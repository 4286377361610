<template>
  <div class="container-fluid">
    <p class="searching" v-if="isSearching">Searching...</p>

    <div class="results-row" v-else>
      <div v-if="errorResult">
        <no-content v-if="!isSearching">
          {{ errorResult }}
        </no-content>
      </div>

      <!-- Category Result -->
      <div v-if="categoryResult">
        <div class="row category-row">
          <app-categories class="col-12 col-sm-6  col-md-4 col-lg-3
                            category-wrapper"
                          v-for="(category, index) in categoryResult"
                          :key="index"
                          :category="category">
          </app-categories>
        </div>
        <div class="row"
              v-if="categoryLastPage &&
                categoryCurrentPage !== categoryLastPage">
            <div class="col text-center">
              <b-button class="btn btn-h3-small btn-h3-blue"
                        type="button"
                        @click="loadMoreCategory">
                Load More
              </b-button>
            </div>
          </div>
      </div>

      <!-- Media Result -->
      <div v-if="mediaResult">
        <div class="row text-center text-lg-left">
          <app-media-list class="media-col col-12 col-sm-6 col-md-4 col-lg-3
                            d-flex"
                          v-for="(media, index) in mediaResult"
                          :key="index"
                          :media="media">
          </app-media-list>
        </div>
        <div class="row"
             v-if="mediaLastPage && mediaCurrentPage !== mediaLastPage">
          <div class="col text-center">
            <b-button class="btn btn-h3-small btn-h3-blue"
                      type="button"
                      @click="loadMoreMedia">
              Load More
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const AppCategories = () =>
  import('@/components/user/common/AppCategories.vue');
  const AppMediaList = () =>
  import('@/components/user/common/AppMediaList.vue');
  const NoContent = () => import('@/components/user/common/NoContent');

  export default {
    name       : 'Search',
    components : {
      NoContent,
      AppCategories,
      AppMediaList,
    },
    data() {
      return {
        searchText          : null,
        filter              : null,
        isSearching         : false,
        errorResult         : '',
        categoryResult      : [],
        mediaResult         : [],
        categoryCurrentPage : 0,
        categoryLastPage    : 0,
        mediaCurrentPage    : 0,
        mediaLastPage       : 0,
        perPage             : 8,
      }
    },
    mounted() {
      if (this.$route.query.keyword && this.$route.query.filter) {
        this.searchText = this.$route.query.keyword;
        this.filter = this.$route.query.filter;
      }
    },
    watch : {
      '$route.query' : function() {
        if (this.$route.name === 'search') {
          this.searchText = this.$route.query.keyword;
          this.filter =  this.$route.query.filter;
        }
      },
      searchText() {
        this.searchMedia();
      },
      filter() {
        this.searchMedia();
      },
    },
    methods : {

      /**
       * Search H3 Media
       */
      searchMedia() {
        // clean values
        this.errorResult = '';
        this.mediaResult = '';
        this.categoryResult = '';
        this.categoryCurrentPage = 0;
        this.categoryLastPage = 0;
        this.mediaCurrentPage = 0;
        this.mediaLastPage = 0;

        if (this.searchText && this.filter) {
          this.isSearching = true;
          if (this.filter === 'category')
            this.searchByCategory(0);
          else
            this.searchByMedia(0);
        } else if (this.searchText) {
          this.$notify({
            group : 'notif',
            type  : 'info',
            text  : 'Please select a filter.',
          });
        } else {
          this.$notify({
            group : 'notif',
            type  : 'info',
            text  : 'Please enter text to search.',
          });
        }
      },

      /**
       * Search by Category Title
       * @param currentPage
       */
      searchByCategory(currentPage = this.categoryCurrentPage) {
        this.$http.get('api/search/category_title', {
          params : {
            page    : currentPage + 1,
            perPage : this.perPage,
            needle  : this.searchText,
          },
        }).then(response => {
          this.categoryCurrentPage = response.data.currentPage;
          this.categoryLastPage = response.data.lastPage;

          if (this.categoryCurrentPage > 1) {
            const arr1 = this.categoryResult;
            const arr2 = response.data.data;

            this.categoryResult = arr1.concat(arr2);
          } else this.categoryResult = response.data.data;

          this.isSearching = false;
        }).catch(() => {
          this.isSearching = false;
          this.errorResult = "Looks like what you're looking for " +
            "isn't here yet. Try another search?";
          
        });
      },

      /**
       * Load More Category Search Result
       */
      loadMoreCategory() {
        this.searchByCategory();
      },

      /**
       * Search by Media Title
       * @param currentPage
       */
      searchByMedia(currentPage = this.mediaCurrentPage) {
        this.$http.get('api/search/media_title', {
          params : {
            page    : currentPage + 1,
            perPage : this.perPage,
            needle  : this.searchText,
          },
        }).then(response => {
          this.mediaCurrentPage = response.data.currentPage;
          this.mediaLastPage = response.data.lastPage;

          if (this.mediaCurrentPage > 1) {
            const arr1 = this.mediaResult;
            const arr2 = response.data.data;

            this.mediaResult = arr1.concat(arr2);
          } else this.mediaResult = response.data.data;

          this.isSearching = false;
        }).catch(() => {
          this.isSearching = false;
          this.errorResult = "Looks like what you're looking for " +
            "isn't here yet. Try another search?";
        });
      },

      /**
       * Load More Media Search Result
       */
      loadMoreMedia() {
        this.searchByMedia();
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/user/search";
</style>
